<template>
  <v-container fluid style="height: 90vh">
    <loader v-if="loading"></loader>
    <v-sheet
      v-else
      class="mx-auto d-flex flex-column"
      elevation="8"
      height="100%"
      :dark="$dark.get()"
    >
      <v-card-title class="ml-5 pb-0 header">Статические страницы</v-card-title>
      <v-slide-group v-model="model" class="pa-4" center-active show-arrows>
        <v-slide-item
          v-for="item in STATIC.staticPages"
          :key="item.id"
          v-slot="{ toggle }"
        >
          <v-card
            v-if="item.id != 1"
            class="ma-4 elevation-6 d-flex flex-column page-card"
            width="550"
            @click="toggle"
          >
            <div
              class="d-flex justify-center align-center flex-column"
              style="
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.6);
                z-index: 2;
                color: white;
                font-size: 25px;
              "
            >
              {{ "Страница " + " '" + item.name + "' " }}
              <v-btn
                color="primary"
                class="mt-4"
                :to="'staticpage/' + item.name"
                >Редактировать</v-btn
              >
            </div>
            <site-page :content="item.content"></site-page>
          </v-card>
        </v-slide-item>
      </v-slide-group>
      <!-- <v-btn class="ml-8" width="120px" color="primary" to="staticpage/new"
        >Добавить</v-btn
      > -->
    </v-sheet>
  </v-container>
</template>
<script>
import SitePage from "../components/SitePage";
import Loader from "../components/Loader";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    SitePage,
    Loader,
  },
  data: () => ({
    model: null,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      STATIC: "Static/STATE",
    }),
  },
  async created() {
    this.loading = true;
    await this.getStatic();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getStatic: "Static/GET_STATIC",
    }),
  },
};
</script>
<style>
.page-card {
  height: 70vh;
}
@media screen and (max-height: 800px) {
  .page-card {
    height: 65vh;
  }
}
</style>
