<template>
  <div class="d-flex flex-column" style="height: inherit; width: inherit">
    <div
      class="pa-2 pt-0"
      style="font-size: 10px; flex-grow: 1; overflow-y: auto"
      v-html="content"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
  },
};
</script>
